@import '_variables.scss';
@import '~@launchpad/styles/style.scss';
@import '~@launchpad/styles/_mixins.scss';

@font-face {
  font-family: 'Proxima-Nova Light';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/FontsFree-Net-Proxima-Nova-Thin.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Proxima-Nova Normal';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/FontsFree-Net-proxima_nova_reg-webfont.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Proxima-Nova Bold';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/FontsFree-Net-Proxima-Nova-Black.otf'); /* IE9 Compat Modes */
}

body {
  font-family: 'Proxima-Nova Normal' !important;
}

.logo {
  width: 200px !important;
  height: auto !important;
  position: relative;
  z-index: 99;
  @media (max-width: 1600px) {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (max-width: 768px) {
    left: auto;
    right: 5px;
    transform: translate(0, 0);
  }
  @media (max-width: 500px) {
    width: 100px !important;
  }
}

.login-overlay-container {
  @include theme-aware('background', 'color-background');
  .logo {
    filter: brightness(0.4);
  }
  .top-element {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .svg-container {
    z-index: 1 !important;
  }
  .center-element {
    h1 {
      @include theme-aware('color', 'color-text');
      font-size: 50px;
      font-weight: 700;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 36px;
      }
    }
    h3 {
      @include theme-aware('color', 'color-text');
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      font-family: 'Proxima-Nova Light';
      max-width: 580px;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
}
.black-btn {
  @include theme-aware('border', 'border-button');
  border-radius: 25.5px !important;
  outline: none !important;
  @include theme-aware('background', 'color-background');
  @include theme-aware('color', 'color-text');
  font-size: 16px !important;
  font-weight: 500;
  text-align: center;
  max-width: 308px;
  max-height: 51px;
  width: 100%;
  height: 100%;
  font-family: 'Proxima-Nova Normal' !important;
  text-transform: uppercase;
  span {
    @include theme-aware('color', 'color-text');
  }
  &:hover {
    @include theme-aware('background', 'color-background');
    @include theme-aware('box-shadow', 'shadow');
  }
  @media (max-width: 450px) {
    font-size: 14px !important;
  }
}
.fill-black-btn {
  text-transform: uppercase;
  @include theme-aware('background', 'color-main');
  @include theme-aware('color', 'color-text');
}

.circle-progress {
  @include theme-aware('color', 'color-main');

  &.light {
    color: white !important;
  }
}
.form-wrapper {
  @media (max-width: 320px) {
    min-width: 280px !important;
  }
}

.screen-layout {
  @include theme-aware('background', 'color-main');
  background-image: none !important;
  @media (max-width: 500px) {
    padding: 10px !important;
  }
  .logo {
    top: 2% !important;
    left: 12% !important;
    @media (max-width: 880px) {
      top: 2% !important;
      left: 52% !important;
      transform: translate(-50%, 0) !important;
    }
  }
  .app-card {
    @include theme-aware('box-shadow', 'shadow');
  }
}
.top-bar {
  @media (max-width: 450px) {
    padding-right: 10px !important;
    // .profile-image-holder {
    //   display: none !important;
    // }
    .name-holder {
      display: none !important;
    }
    .top-bar-vertical-line {
      margin: 0 12px !important;
    }
  }
}

.child-container {
  h4 {
    @include theme-aware('color', 'color-text');
    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }
  p {
    @media (max-width: 768px) {
      font-size: 14px !important;
    }
  }
}

.close-icon {
  @include theme-aware('color', 'color-main');
}

.validation-message {
  left: 105% !important;
  width: 100% !important;
  top: 50% !important;
  transform: translate(0, -30%) !important;
  @media (max-width: 768px) {
    transform: none !important;
    left: 15px !important;
    top: 100% !important;
  }
}

.dash-logo {
  // padding: 45px 0 !important;
  img {
    width: 150px !important;
    height: auto;
  }
}

.send-load-btn-holder {
  justify-content: center !important;
}

.profile-container {
  @include theme-aware('box-shadow', 'shadow');
}

.footer-column {
  h2 {
    color: #ffffff;
    font-size: 24px;
    padding-bottom: 20px;
  }
  sup {
    font-weight: bold;
    font-size: 14px;
    left: -6px;
  }
}
.flip-card-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  .rotate-btn {
    max-width: 60px !important;
  }
}
.card-navigation-wrapper {
  margin-left: 0 !important;
  .card-not-active-overlay {
    border-radius: 15px;
    @media (max-width: 500px) {
      top: 62px;
      height: 78% !important;
    }
  }
}
.card-page-card-navigation ul {
  @include theme-aware('border-left', 'border-main');
}

.card-page-card-navigation {
  width: 60px;
}

.flippy-front,
.flippy-back {
  // position: relative;
  max-width: 375px;
  border-radius: 16px;
  max-height: 240px;
  height: auto;
  width: 100%;
  padding: 0 !important;
  // transform: rotateY(0deg);
  background: transparent;
  box-shadow: none !important;
  // -webkit-backface-visibility: hidden;
  // backface-visibility: hidden;
  transform-style: preserve-3d;
  cursor: pointer;
}
.card-pan {
  position: absolute;
  bottom: 80px;
  left: 35px;
  color: #ffffff;
  font-size: 20px;
  @media (max-width: 375px) {
    bottom: 75px;
  }
  @media (max-width: 320px) {
    left: 15px;
    bottom: 60px;
  }
}
.card-expires {
  position: absolute;
  color: #ffffff;
  @media (max-width: 375px) {
    bottom: 75px;
  }
  @media (max-width: 320px) {
    right: 50px;
    bottom: 60px;
  }
}

.MuiOutlinedInput-notchedOutline {
  @include theme-aware('border-color', 'color-main');
  border-radius: 25.5px !important;
}
#outlined-error {
  .MuiOutlinedInput-notchedOutline {
    @include theme-aware('border-color', 'color-error');
  }
}

.transaction-declined {
  span {
    @include theme-aware('color', 'color-error');
  }
}

.transaction-status-declined {
  .transaction-status {
    .detail-value {
      @include theme-aware('color', 'color-error');
    }
  }
}

.points-redeem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  @include theme-aware('border', 'border-main');
  border-radius: 30px;
  img {
    @include theme-aware('filter', 'filter-invert');
  }
  i {
    @include theme-aware('color', 'color-main');
  }
  &:hover {
    @include theme-aware('box-shadow', 'shadow');
  }
}
