@import '_mixins.scss';
@import '_variables.scss';

@font-face {
  font-family: 'Avenir Light';
  font-style: normal;
  font-weight: 300;
  src: url('~@launchpad/fonts/AvenirLTStd-Book.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Avenir Normal';
  font-style: normal;
  font-weight: 500;
  src: url('~@launchpad/fonts/AvenirLTStd-Roman.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Avenir Bold';
  font-style: normal;
  font-weight: 900;
  src: url('~@launchpad/fonts/AvenirLTStd-Black.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Credit-Card-Font';
  font-style: normal;
  font-weight: 500;
  src: url('~@launchpad/fonts/Credit-Card-Font.ttf'); /* IE9 Compat Modes */
}
.noscroll {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Avenir Normal';
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0 !important;
  padding: 0;
}
ul,
li {
  list-style-type: none;
}

.center-text {
  text-align: center;
}

.logo {
  width: 120px;
  height: 80px;
}

.login-overlay-container {
  @include theme-aware('background', 'color-background');
  min-height: 100vh;
  height: 100%;
  padding: 50px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .overlay-background {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 100%;
    position: absolute;
    opacity: 0.5;
    top: 0;
    right: -15%;
    bottom: 0;
    z-index: -1;
  }
  .sign-up-holder {
    display: flex;
    flex-direction: row;
    p {
      @include theme-aware('color', 'color-text');
      font-size: 18px;
      padding-right: 5px;
      margin: 0;
    }
    .sign-up-link {
      font-size: 18px;
      font-family: 'Avenir Bold';
      @include theme-aware('color', 'color-text');
      text-decoration: none !important;
    }
  }
  .svg-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .center-element {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 200px;
  }
  h1 {
    font-size: 50px;
  }
  h3 {
    font-family: 'Avenir Light';
    font-size: 20px;
    padding-top: 25px;
    padding-bottom: 35px;
    @include theme-aware('color', 'color-text');
  }
}

.gradient-button {
  width: 100%;
  border-radius: 5px;
  @include theme-aware(
    'background',
    'color-background'
  ); /* For browsers that do not support gradients */
  @include theme-aware('background-image', 'main-linear-gradient');
  min-height: 55px;
  max-width: 310px;
  padding: 2px;
  text-transform: uppercase;
  .custom-btn {
    border-radius: 5px;
    @include theme-aware('color', 'color-main');
    width: 100%;
    height: 100%;
    min-height: 55px;
    outline: none !important;
    font-size: 16px;
    text-transform: uppercase;
    span {
      @include theme-aware('color', 'color-text');
    }
    &:hover {
      background-color: transparent !important;
    }
  }
  .custom-inverted-btn {
    border-radius: 5px;
    @include theme-aware('background', 'color-main');
    @include theme-aware('color', 'color-text');
    width: 100%;
    height: 100%;
    min-height: 55px;
    outline: none !important;
    font-size: 16px;
    text-transform: uppercase;
    span {
      @include theme-aware('color', 'color-text');
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}
.gradient-btn-custom {
  text-transform: uppercase;
  background-image: none !important;
}

.inner {
  padding-left: 232px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.screen-layout {
  width: 100%;
  @include theme-aware('background', 'color-main');
  min-height: 100vh;
  height: 100%;
  padding: 120px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .logo {
    position: absolute;
    top: -115px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .app-card {
    @include theme-aware('background', 'color-background');
    width: 100%;
    height: 100%;
    min-height: 700px;
    max-width: 1220px;
    @include theme-aware('box-shadow', 'shadow');
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 160px;
    padding-top: 60px;
  }
  .svg-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .sign-up-holder {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    p {
      @include theme-aware('color', 'color-text');
      font-size: 18px;
      padding-right: 5px;
      margin: 0;
    }
    .sign-up-link {
      font-size: 18px;
      font-family: 'Avenir Bold';
      @include theme-aware('color', 'color-text');
      text-decoration: none !important;
    }
  }
}
.child-container {
  z-index: 99999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 35px;
    text-align: center;
    letter-spacing: 0.22px;
    line-height: 42px;
    @include theme-aware('color', 'color-text');
    margin: 0;
    padding-bottom: 27px;
    @media (max-width: 545px) {
      margin-bottom: 30px;
      font-size: 30px;
    }
  }
  p,
  a {
    color: $GRAY_COLOR;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 27px;
  }
}
.validation-message {
  font-size: 12px;
  @include theme-aware('color', 'color-error');
}
.input-group {
  label,
  i {
    @include theme-aware('color', 'color-main');
  }
  @include theme-aware('color', 'color-main');
}
.MuiInputBase-root {
  @include theme-aware('color', 'color-main');
}
.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline.Mui-focused:not(.Mui-disabled):before {
  @include theme-aware('border-bottom', 'border-main');
  border-width: 2px !important;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  @include theme-aware('border-bottom', 'border-main');
}
.input-group .validation-message {
  position: absolute;
  bottom: -16px;
  left: 0;
}

.login-form {
  width: 100%;
}
#send-money-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-form .input-group {
  margin: 0;
  padding: 0;
}
.login-form .login-input-wrapper {
  margin-bottom: 20px;
  position: relative;
  img.input-icon {
    position: absolute;
    z-index: 1;
    top: 25px;
  }
}
.form-wrapper {
  width: 100%;
  max-width: 310px;
  min-width: 310px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
  }
  .form-button-container {
    display: flex;
    justify-content: center;
  }
  .submitButtonWrapper {
    margin-top: 30px;
  }
}

.checkbox-wrapper {
  label {
    @include theme-aware('color', 'color-gray');
  }
}

.squaredTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  @include theme-aware('border', 'border-main');
  @include theme-aware('background', 'color-background');
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 4px;
    top: 4px;
    @include theme-aware('color', 'color-main-darker');
    &:after {
      content: '';
      width: 14px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 2px;
      transition: opacity 0.3s ease;
      border: 3px solid;
      @include theme-aware('color', 'color-main-darker');
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}

/* React Modal - START */

.ReactModal__Body--open {
  overflow: hidden;
}

// Modal
.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 999999999;
}

.react-modal-content {
  padding: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock-card-modal {
  @include theme-aware('background', 'color-background');
  @include theme-aware('box-shadow', 'shadow');
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1,
  h2,
  h3,
  p,
  a {
    @include theme-aware('color', 'color-text');
  }
  .close-btn {
    @media (max-width: 450px) {
      min-height: 540px;
    }
    width: 100%;
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: 35px;
  }
  .progress-text {
    @include theme-aware('color', 'color-main');
  }
  i {
    @include theme-aware('color', 'color-text');
  }
}

.transaction-modal {
  @include theme-aware('background', 'color-background');
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  max-width: 620px;
  max-height: 550px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include theme-aware('color', 'color-text');
  overflow-y: scroll;
  .close-btn {
    width: 100%;
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: 35px;
    i {
      @include theme-aware('color', 'color-text');
    }
  }
  a {
    @include theme-aware('color', 'color-main');
    margin-bottom: 20px;
    text-decoration: 'underline';
  }
  .icon-holder {
    @include theme-aware('background', 'color-main');
    @include theme-aware('color', 'color-text');
    @include theme-aware('box-shadow', 'shadow');
    border-radius: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 10px 0;
  }
  .tx-amount {
    @include theme-aware('color', 'color-text');
    font-size: 68px;
    font-weight: 800;
    line-height: 82px;
    text-align: center;
    margin-top: 20px;
  }
  h4 {
    @include theme-aware('color', 'color-text');
    text-align: center;
    margin-bottom: 10px;
  }

  .transaction-details {
    width: 100%;
    margin: 20px 0;

    > .row {
      width: 100%;
      margin: 0 !important;
      min-width: 300px;
    }

    .detail-label {
      flex: 1;
      text-align: right;
      padding-right: 5px;
    }

    .detail-value {
      flex: 1;
      text-align: left;
      padding-left: 5px;
    }
  }

  .section-header {
    width: 100%;
    padding: 20px;
    @include theme-aware('color', 'color-text');
    @include theme-aware('background', 'color-main');

    h5 {
      @include theme-aware('color', 'color-text');
    }
  }
}
/* React Modal - END */

.toast-container {
  z-index: 999999999 !important;
}
.toast-mesaage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 99999;
  display: flex;
  align-items: center;
  // box-shadow: 10px 7px 18px 0 rgba(85, 84, 173, 0.5);
  @include theme-aware('box-shadow', 'shadow');
  height: 60px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  p {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    @include theme-aware('color', 'color-main');
    cursor: pointer;
  }
}

.top-bar {
  height: 65px;
  @include theme-aware('background', 'color-main');
  max-width: calc(100% - 232px);
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 90px;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 999999;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 10px;
    margin: 0;
  }
}
.phone-menu-holder {
  width: 60px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 0;
  @media (min-width: 769px) {
    display: none;
  }
  .phone-menu-icon {
    width: 30px;
    height: 3px;
    @include theme-aware('background', 'color-text');
    margin: 3px 0;
  }
}
.top-bar-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .profile-image-holder {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    display: flex;
  }
  h4 {
    @include theme-aware('color', 'color-text');
    font-size: 16px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }
  p {
    @include theme-aware('color', 'color-text');
    font-size: 10px;
    text-align: left;
    margin: 0;
  }
  .name-holder {
    padding-left: 10px;
  }
  .top-bar-vertical-line {
    @include theme-aware('background', 'color-text');
    height: 28px;
    width: 1px;
    margin: 0 22px;
    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }
  i {
    @include theme-aware('color', 'color-text');
  }
}
.theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    @include theme-aware('color', 'color-text');
  }
}
.app-footer {
  min-height: 305px;
  @include theme-aware('background', 'color-background');
  width: 100%;
  padding: 50px 0;
  position: absolute;
  z-index: 999999;
  @include theme-aware('color', 'color-text');
  @include theme-aware('box-shadow', 'shadow');

  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @include theme-aware('color', 'color-text');

    h3 {
      @include theme-aware('color', 'color-text');
      font-size: 18px;
      text-align: left;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    .footer-link {
      @include theme-aware('color', 'color-text');
      font-size: 16px;
      text-align: left;
      text-decoration: none !important;
      padding-bottom: 8px;
    }
    i {
      @include theme-aware('color', 'color-text');
    }
    .pannovate-logo {
      padding-top: 100px;
      @media (max-width: 768px) {
        padding-top: 20px;
      }
      p {
        font-style: italic;
        @include theme-aware('color', 'color-text');
        font-size: 14px;
        text-align: left;
      }
      img {
        width: 170px;
        height: auto;
        @include theme-aware('filter', 'filter-invert');
      }
    }
  }
  .last-line {
    position: absolute;
    bottom: 55px;
    padding-left: 15px;
    @media (max-width: 768px) {
      bottom: 10px;
    }
    p {
      @include theme-aware('color', 'color-text');
      font-size: 16px;
      text-align: left;
    }
  }
}
.mobile-side-bar {
  left: 0 !important;
}
.phone-overlay {
  width: calc(100vw - 132px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 132px;
  top: 0;
  @media (min-width: 768px) {
    display: none;
  }
}
.side-bar {
  height: 100%;
  @include theme-aware('background', 'color-background');
  @include theme-aware('box-shadow', 'shadow-side-bar');
  width: 232px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  transition: left 0.3s linear;
  @media (max-width: 768px) {
    left: -140px;
    top: 65px;
    width: 132px !important;
  }
  .dash-logo {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100px;
      height: auto;
    }
  }
  .sideMenuList {
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
      li {
        a {
          justify-content: center !important;
          span {
            display: none !important;
          }
        }
      }
    }
    li {
      height: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        @include theme-aware('background', 'color-main');
        i,
        span {
          @include theme-aware('color', 'color-text');
        }
      }
      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
        @include theme-aware('color', 'color-main');
      }
      i {
        font-size: 36px;
        @include theme-aware('color', 'color-main');
      }
    }
    .active {
      position: relative;
    }
    .active-side-menu-line {
      width: 6px;
      height: 100%;
      position: absolute;
      top: 0;
      right: -6px;
      @include theme-aware('background', 'color-main-darker');
    }
  }
  .side-bar-content-wrapper {
    position: sticky;
    top: 0;
    left: 0;
  }
}
.side-bar-top {
  top: 0;
  transition: top 0.3s linear;
}

.dashboard-main {
  width: 100%;
  position: relative;
  padding-bottom: 120px;
  min-height: 70vh;
  @include theme-aware('background', 'color-background');
  @media (max-width: 768px) {
    padding: 64px 0;
  }
  .svg-container {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}
.dashboard-header {
  width: 100%;
  // min-height: 400px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
  .totalBalances {
    width: 100%;
    padding-top: 20px;
    @include theme-aware('border-top', 'border-main');
  }

  .progress-number {
    @include theme-aware('color', 'color-text');
  }
  .balance-holder {
    width: 100%;
    // max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
    .total-holder {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .total-item-holder {
        flex: 1;
        text-align: center;
      }
      h3 {
        @include theme-aware('color', 'color-main');
        font-size: 16px;
        font-weight: 400;
      }
      h1 {
        @include theme-aware('color', 'color-text');
        font-weight: 600;
        text-align: center;
        padding-top: 10px;
      }
      h5 {
        color: $GRAY_COLOR;
        font-size: 13px;
        text-align: center;
      }
      h4 {
        @include theme-aware('color', 'color-text');
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        padding-top: 10px;
      }
    }
  }
}
.progress-text {
  @include theme-aware('color', 'color-main');
  font-size: 16px;
  padding: 0 10px;
}
.exchange-icon-img {
  @include theme-aware('filter', 'filter-invert');
}
.send-load-btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.send-load-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include theme-aware('color', 'color-text');
  text-transform: uppercase;
  max-width: 200px;
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    @include theme-aware('color', 'color-main');
  }
  .send-load-icon-wrapper {
    @include theme-aware('background', 'color-main');
    border-radius: 26px;
    @include theme-aware('box-shadow', 'shadow');
    width: 52px;
    height: 52px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5px;
    text-decoration: none !important;
    @include theme-aware('color', 'color-text');
    i {
      @include theme-aware('color', 'color-text');
    }
    &:hover {
      @include theme-aware('color', 'color-main');
    }
  }
}

.appContainer {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app-widget {
  height: 500px;
  max-width: 600px;
  width: 100%;
  @include theme-aware('background', 'color-background');
  border-radius: 10px;
  @include theme-aware('box-shadow', 'shadow');
  margin: 10px auto;
  @media (max-width: 992px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .card-header {
    @include theme-aware('background', 'color-main');
    height: 55px;
    border-radius: 10px 10px 0 0;
    @include theme-aware('box-shadow', 'shadow');
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      @include theme-aware('color', 'color-text');
      font-size: 18px;
      text-align: left;
      padding-left: 15px;
      opacity: 1;
    }
    i {
      @include theme-aware('color', 'color-text');
    }
  }
  .card-content {
    width: 100%;
  }
}

.notificationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  min-height: 80px;
  &:hover {
    opacity: 0.7;
  }

  &.declined {
    background: #c1c1c1;
  }
  p {
    @include theme-aware('color', 'color-text');
    font-size: 14px;
  }

  span {
    padding: 0 12px;
    word-break: break-all;
    font-size: 13px;
    @include theme-aware('color', 'color-text');

    @media (max-width: 545px) {
      padding: 0 5px;
    }
  }
}

.notificationItem:first-child {
  border: none;
}
.transactionItem:first-child {
  border: none;
}

.transactionItem {
  // @include theme-aware('background', 'color-background');
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  @media (max-width: 545px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }

  &.statements {
    min-height: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 80px !important;
  }

  .iconHolder {
    display: flex;
    height: auto;
    min-width: 40px;
    @include theme-aware('background', 'border-main');
    @include theme-aware('border', 'border-main');
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    i {
      @include theme-aware('color', 'color-text');
    }
  }
  .transaction-title {
    @include theme-aware('color', 'color-text');
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @media (max-width: 450px) {
      font-size: 12px;
    }
  }
  .transaction-date {
    @include theme-aware('color', 'color-text');
    font-size: 14px;
    opacity: 0.5;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @media (max-width: 450px) {
      font-size: 10px;
    }
  }
  .transaction-amount-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include theme-aware('color', 'color-text');

    .amount {
      flex: 1;
      font-size: 20px;
      align-self: flex-end;
      text-align: right;
      font-weight: 600;
      @include theme-aware('color', 'color-text');
      @media (max-width: 450px) {
        font-size: 12px;
      }
    }
  }
}

.go-to-inbox {
  position: absolute;
  right: 30px;
  bottom: 10px;
  @include theme-aware('color', 'color-main');
  font-size: 16px;
  font-weight: 500;
  &:hover {
    @include theme-aware('color', 'color-main');
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.amount-input {
  border: none;
  background: transparent;
  outline: none;
  font-size: 30px;
  text-align: center;
  @include theme-aware('color', 'color-text');
  max-width: 100%;
  @include theme-aware('border-bottom', 'border-button');
  &::-webkit-input-placeholder {
    color: #fff !important;
  }

  &::-moz-placeholder {
    color: #fff !important;
  }

  &::-moz-placeholder {
    color: #fff !important;
  }

  &::-ms-input-placeholder {
    color: #fff !important;
  }
}

.account-holder {
  margin-top: 100px;
}

.account-holder,
.terms-holder {
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.profile-container {
  @include theme-aware('background', 'color-background');
  border-radius: 10px;
  @include theme-aware('box-shadow', 'shadow');
  height: 100%;
  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 450px) {
    padding: 10px;
  }
  h1 {
    @include theme-aware('color', 'color-main');
    font-size: 20px;
    font-weight: 500;
  }
  h4 {
    @include theme-aware('color', 'color-text');
    font-size: 18px;
    font-weight: 500;
    padding: 30px 0;
  }
  .owner-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 998px) {
      flex-direction: column;
      justify-content: center;
    }
    .profile-img-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 998px) {
        margin-bottom: 30px;
      }
      @media (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
      }
      .profile-image-holder {
        border-radius: 60px;
        @include theme-aware('box-shadow', 'shadow');
        width: 120px;
        height: 120px;
        overflow: hidden;
        display: flex;
      }
      .owner-name {
        margin-left: 20px;
        @media (max-width: 500px) {
          margin-top: 30px;
          margin-left: 0;
        }
        p {
          @include theme-aware('color', 'color-text');
          font-size: 14px;
          opacity: 0.5;
          @media (max-width: 500px) {
            text-align: center;
          }
        }
        h3 {
          @include theme-aware('color', 'color-text');
          font-size: 30px;
          line-height: 40px;
          opacity: 0.9;
          @media (max-width: 500px) {
            text-align: center;
          }
        }
      }
    }
  }
  .info-wrapper {
    .profile-header {
      @include theme-aware('background', 'color-main');
      border-radius: 5px;
      padding: 10px 35px;
      h3 {
        @include theme-aware('color', 'color-text');
        font-size: 18px;
        font-weight: 800;
      }
    }
    ul {
      padding-top: 18px;
      li {
        @include theme-aware('color', 'color-text');

        opacity: 0.7;
        font-size: 16px;
        text-align: left;
        span {
          color: black !important;
          opacity: 1 !important;
        }
      }
      .red-info {
        @include theme-aware('color', 'color-error');
        font-style: italic;
      }
    }
  }
  .privacy-text {
    @include theme-aware('color', 'color-text');
    font-size: 20px;
    font-weight: 500;
    .terms-link {
      @include theme-aware('color', 'color-text');
      font-size: 20px;
      font-weight: 500;
      @include theme-aware('border-bottom', 'border-button');
      text-decoration: none !important;
    }
  }
}

.card-navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0 auto;
  }
  .card-not-active-overlay {
    width: 100%;
    max-width: 375px;
    height: 100%;
    @include theme-aware('background', 'color-main');
    border-radius: 11px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.23),
      inset 0 -9px 9px 0 rgba(0, 0, 0, 0.5);
    opacity: 0.9;
    position: absolute;
    top: 0;
    // left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8;
    h3 {
      @include theme-aware('color', 'color-text');
      font-size: 22px;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
    }
    i {
      @include theme-aware('color', 'color-text');
    }
  }
}
.card-page-card-image {
  max-width: 375px;
  border-radius: 16px;
  // margin-left: 50px;
  max-height: 240px;
  height: auto;
  width: 100%;
}
.card-wrapper {
  display: flex;
  flex: 1;
  min-width: 420px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 100px;
  min-height: 90vh;
  @media (max-width: 500px) {
    padding-top: 50px;
  }
  @media (max-width: 700px) {
    min-height: 0;
  }
}
.card-page-card-navigation {
  width: 50px;
  @media (max-width: 500px) {
    transform: rotate(90deg);
    position: relative;
    top: -60px;
  }
  .not-active {
    pointer-events: none;
    opacity: 0.5;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      width: 60px;
      height: 60px;
      @include theme-aware('background', 'color-background');
      display: flex;
      align-items: center;
      justify-content: center;
      transition: width 0.25s ease-out;
      cursor: pointer;
      i {
        @include theme-aware('color', 'color-main');
      }
      &:hover {
        width: 100px;
        border-radius: 0 13px 13px 0;
        @include theme-aware('border', 'border-main');
        @include theme-aware('background', 'color-main');
        i {
          @include theme-aware('color', 'color-text');
        }
      }
      &.active {
        width: 100px;
        @include theme-aware('background', 'color-main');
        @include theme-aware('border', 'border-main');
        border-radius: 0 13px 13px 0;
        i {
          @include theme-aware('color', 'color-text');
        }
      }
    }
  }

  li:nth-child(1) {
    @include theme-aware('border-right', 'border-main');
    @include theme-aware('border-top', 'border-main');
    @include theme-aware('border-bottom', 'border-main');
    border-top-right-radius: 13px;
  }
  li:nth-child(2) {
    @include theme-aware('border-right', 'border-main');
  }
  li:nth-child(3) {
    @include theme-aware('border-right', 'border-main');
    @include theme-aware('border-top', 'border-main');
    @include theme-aware('border-bottom', 'border-main');
    border-bottom-right-radius: 13px;
  }
}

.details-holder {
  padding: 50px 15px 15px;
  position: relative;
  .top-line {
    max-width: 400px;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    @include theme-aware('background', 'color-gray');
    transform: translate(-50%, 0);
  }
  ul {
    padding: 0;
  }
  li {
    @include theme-aware('color', 'color-text');
    padding-bottom: 24px;
  }
  .control-card li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .link-for-terms {
    @include theme-aware('color', 'color-text');
    font-size: 16px;
    text-decoration: none !important;
    @include theme-aware('border-bottom', 'border-main');
  }
  h3 {
    @include theme-aware('color', 'color-text');
    font-size: 18px;
    text-align: center;
  }
  p {
    @include theme-aware('color', 'color-text');
    font-size: 14px;
    opacity: 0.5;
    text-align: center;
  }
}

.switch-container {
  position: relative;
  display: flex;
  align-items: center;
}

input[type='checkbox'].switch {
  position: absolute;
  opacity: 0;
}

input[type='checkbox'].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #d5d5d5;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type='checkbox'].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type='checkbox'].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type='checkbox'].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type='checkbox'].switch:checked + div {
  width: 47px;
  background-position: 0 0;
  @include theme-aware('background', 'color-success');
}

input[type='checkbox'].switch + div {
  width: 47px;
  height: 24px;
}

input[type='checkbox'].switch:checked + div {
  @include theme-aware('background', 'color-success');
}

input[type='checkbox'].switch + div > div {
  float: left;
  width: 22px;
  height: 22px;
  margin: 1px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  background-color: #ffffff;
}

input[type='checkbox'].bigswitch.switch + div > div {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(23px, 0, 0);
  transform: translate3d(23px, 0, 0);
}

.pin-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 60px;
  margin-left: 30px;
  @media (max-width: 450px) {
    margin-left: 15px;
  }
  .pin-number-holder {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    @include theme-aware('border', 'border-main');
    border-radius: 4px;
    min-height: 64px;
    max-width: 64px;
    margin: 0 5px;
    position: relative;
    span {
      @include theme-aware('color', 'color-text');
      font-size: 40px;
      font-weight: 500;
      text-align: center;
    }
  }
  h4 {
    @include theme-aware('color', 'color-text');
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    min-width: 52px;
    text-align: center;
  }
}

.circle-progress {
  @include theme-aware('color', 'color-main');
}

.light {
  color: white !important;
}

.stat-holder {
  h3 {
    @include theme-aware('color', 'color-text');
    font-size: 20px;
    font-weight: 500;
  }
  p {
    @include theme-aware('color', 'color-text');
    font-size: 18px;
    opacity: 0.5;
  }
  span {
    @include theme-aware('color', 'color-text');
    font-size: 18px;
    font-weight: 600;
  }
}
.last-trans-overlay {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 80px;
  // background-image: linear-gradient(
  //   rgba(255, 255, 255, 0.3),
  //   rgba(255, 255, 255, 0.6),
  //   white,
  //   white
  // );
  @include theme-aware('background-image', 'last-transaction-linear-gradient');
}
.chart-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  span {
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    margin: 0 10px 0 10px;
    @include theme-aware('color', 'color-text');
    font-size: 14px;
    cursor: pointer;
    &:hover {
      @include theme-aware('color', 'color-text');
      @include theme-aware('background', 'color-main');
      border: none !important;
    }

    &.active {
      @include theme-aware('color', 'color-text');
      @include theme-aware('background', 'color-main');
      border: none !important;
    }

    @media (max-width: 545px) {
      padding: 5px 8px;
    }
  }
}

.statements-transaction-wrapper {
  padding-right: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 992px) {
    border: none;
    padding: 0;
    margin-bottom: 50px;
  }
}

.filter-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    @include theme-aware('color', 'color-text');
    font-size: 17px;
    opacity: 0.6;
  }
  .btn-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    span {
      border-radius: 5px;
      @include theme-aware('border', 'color-main');
      width: 90px;
      height: 45px;
      font-size: 15px;
      @include theme-aware('color', 'color-text');
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        @include theme-aware('color', 'color-text');
        @include theme-aware('background', 'color-main');
        border: none !important;
      }
      &.active {
        @include theme-aware('color', 'color-text');
        @include theme-aware('background', 'color-main');
        border: none !important;
      }

      @media (max-width: 545px) {
        padding: 5px 8px;
      }
    }
  }
}
.datePickerHolder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0;
  .statement-date-text {
    @include theme-aware('color', 'color-text');
    opacity: 0.5;
    min-width: 50px;
    font-size: 15px;
    font-weight: 500;
  }
  .statement-date {
    background: transparent !important;
    @include theme-aware('border', 'border-main');
    border-radius: 6px;
    padding: 5px;
    @include theme-aware('color', 'color-text');
  }
  .react-datepicker__close-icon::after {
    @include theme-aware('background', 'color-main');
  }
}
.search-wrapper {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  @include theme-aware('border-bottom', 'border-main');
  i.fa-search {
    position: absolute;
    @include theme-aware('color', 'color-text');
    opacity: 0.6;
    top: 10px;
    left: 15px;
  }

  input {
    background: transparent;
    padding-left: 50px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    @include theme-aware('color', 'color-text');
    height: 38px;
    width: 100%;

    &::-moz-placeholder {
      @include theme-aware('color', 'color-text');
    }
    &::-webkit-input-placeholder {
      @include theme-aware('color', 'color-text');
    }
    &:-ms-input-placeholder {
      @include theme-aware('color', 'color-text');
    }
  }
}
.filter-button-wrapper {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
}

.app-list-item {
  @include theme-aware('color', 'color-text');
}

.load-title {
  @include theme-aware('color', 'color-text');
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.load-subtitle {
  @include theme-aware('color', 'color-text');
  font-size: 18px;
  text-align: center;
  opacity: 0.9;
  max-width: 450px;
}
.load-info {
  margin: 20px 0;
  .column {
    display: flex;
    flex-direction: column;
  }
  .first-line {
    span {
      @include theme-aware('color', 'color-main');
      font-size: 16px;
      text-align: left;
      padding-bottom: 10px;
    }
  }
  .second-line {
    span {
      @include theme-aware('color', 'color-main');
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 10px;
    }
  }
}
.customer-no {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    @include theme-aware('color', 'color-main');
    font-size: 16px;
    text-align: center;
  }
  .ref-number-holder {
    @include theme-aware('color', 'color-text');
    font-size: 38px;
    text-align: center;
    margin: 10px 0;
    padding: 20px;
    @include theme-aware(
      'background',
      'color-background'
    ); /* For browsers that do not support gradients */
    @include theme-aware('background-image', 'main-linear-gradient');
    border-radius: 5px;
    width: 100%;
    max-width: 450px;
  }
  h6 {
    @include theme-aware('color', 'color-text');
    opacity: 0.6;
    font-size: 14px;
    font-style: italic;
    text-align: center;
    max-width: 450px;
  }
}

.black-btn {
  @include theme-aware('border', 'border-button');
  border-radius: 25.5px;
  outline: none !important;
  background-color: transparent;
  @include theme-aware('color', 'color-text');
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  max-width: 308px;
  min-height: 51px;
  width: 100%;
  height: 100%;
  font-family: 'Proxima-Nova Normal' !important;
  text-transform: uppercase;
  span {
    @include theme-aware('color', 'color-text');
  }
  &:hover {
    background-color: transparent;
  }
}
.full-black-btn {
  text-transform: uppercase;
  @include theme-aware('background', 'color-text');
}

.modal-icon {
  @include theme-aware('color', 'color-main');
}

// NOTIFICATIONS PAGE

.notifications-header {
  display: flex;
  justify-content: flex-end;
  .select-all-button {
    position: relative;
    @include theme-aware('background', 'color-main');
    border-radius: 50px;
    padding: 10px 30px;
    outline: none !important;
    @include theme-aware('border', 'border-main');
    span {
      @include theme-aware('color', 'color-text');
      font-size: 15px;
      font-weight: bold;
    }
  }

  .notifications-dropdown-button {
    position: relative;
    background: white;
    border-radius: 50px;
    padding: 10px 30px;
    outline: none !important;
    @include theme-aware('border', 'border-main');
    span {
      @include theme-aware('color', 'color-text');
      font-size: 15px;
    }
  }
}

.dropdown-toggle::after {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 12px;
  content: '';
  border-top: 0.3em solid $MAIN_COLOR !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.notifications-col-12 {
  padding: 15px;
}

.notifications-dropdown {
  margin-left: 20px;

  .dropdown-menu {
    border-radius: 10px;
    border-color: #cfd1d7;

    li {
      @include theme-aware('color', 'color-main');
      padding: 7px !important;
      font-size: 12px !important;
    }
  }

  #moreDropdown {
    right: -38px;
  }

  .check-icon {
    position: absolute;
    right: 1px;
  }
}

.notifications-body {
  height: 70vh;
}

.inner-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    @include theme-aware('color', 'color-text');
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  p {
    @include theme-aware('color', 'color-text');
    font-size: 16px;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  img {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-top: 50px;
  }
}
.input-wrapper {
  max-width: 500px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .input-pin {
    max-width: 5%;
    margin: 10px;
    flex: 1;
    border: none;
    background: transparent;
    @include theme-aware('border-bottom', 'border-main');
    text-align: center;
    font-size: 24px;
    @include theme-aware('color', 'color-text');
    outline: none;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  @include theme-aware('border-color', 'color-text');
  border-radius: 10px !important;
}
#outlined-error {
  .MuiOutlinedInput-notchedOutline {
    @include theme-aware('border-color', 'color-error');
  }
}

.MuiFormLabel-root.Mui-focused {
  color: inherit !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.report-number {
  text-decoration: none !important;
  font-size: 22px;
  @include theme-aware('color', 'color-main');
  &:hover {
    opacity: 0.7;
  }
}

.fx-calculator {
  @media (min-width: 768px) {
    margin-top: 100px;
  }
}
.fee-calculator {
  @include theme-aware('color', 'color-text');
}

.exchange-fee-title {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  @include theme-aware('background', 'color-main');
  @include theme-aware('color', 'color-text');
}

.change-password-form-wraper {
  width: 100%;
  max-width: 500px;
  > div {
    width: 100%;
    max-width: 500px;
  }
  .submitButtonWrapper {
    .gradient-button {
      margin: auto;
    }
  }
}

.cms-content {
  @include theme-aware('color', 'color-text');

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: bold;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
  }

  p {
    margin: 5px;
  }

  p {
    margin: 10px 0;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }

  table.bordered td,
  table.bordered th {
    border: 1px solid black;
  }

  table tr th {
    background-color: #b7b7b7;
    padding: 5px;
  }

  table tr td {
    padding: 5px;
  }

  table tr.noBorder th:first-child {
    border-right: none;
  }

  strong {
    font-weight: bold !important;
  }

  ul {
    // list-style: none;
    margin: 0;
    padding: 0;
    list-style: disc !important;
    list-style-type: disc !important;
    list-style-position: inside !important;
  }

  ul li {
    margin: 10px 0;
    list-style-type: disc !important;
  }

  ul li .number {
    display: flex;
    font-weight: bold;
  }

  ul li .content {
    margin-left: 10px;
  }

  ul.bulletted {
    padding-left: 20px;
    margin-left: 10px;
    list-style: disc !important;
    list-style-type: disc !important;
  }

  ul.bulletted li {
    display: list-item;
    list-style: disc !important;
    list-style-type: disc !important;
  }
}

.legal-title {
  @include theme-aware('color', 'color-text');
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 20px;
    font-size: 1.8rem;
  }
}

.Collapsible {
  width: 100%;
  margin-bottom: 20px;
  @include theme-aware('border-bottom', 'border-main');
  padding-bottom: 20px;
  position: relative;
  font-size: 18px;
  span {
    @include theme-aware('color', 'color-main');
    cursor: pointer;
  }
  .full-text {
    font-weight: 300;
  }
}

.ql-size-huge,
.ql-size-large {
  font-size: 20px !important;
}

.notification-badge {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  @include theme-aware('background', 'color-error');
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  position: absolute;
  left: -3px;
  top: -3px;
  .unread-notification-number {
    @include theme-aware('color', 'color-text');
    font-size: 10px;
    padding-bottom: 1px;
    text-align: center;
  }
}

.call-phone-wrapper {
  i {
    @include theme-aware('color', 'color-success');
  }
}

.fx-redeem-holder {
  @include theme-aware('background', 'color-background');
  @include theme-aware('box-shadow', 'shadow');
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.fx-redeem-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 20px;
  p {
    @include theme-aware('color', 'color-text');
  }
  img {
    @include theme-aware('filter', 'filter-invert');
  }
  i {
    @include theme-aware('color', 'color-text');
  }
}
.fx-redeem-content {
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  p,
  i {
    @include theme-aware('color', 'color-main');
  }
  h6 {
    @include theme-aware('color', 'color-text');
  }

  .fx-redeem-input-holder {
    @include theme-aware('border-bottom', 'border-main');
    p {
      @include theme-aware('color', 'color-main');
    }
  }
}

.exchange-confirmation-holder {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  @include theme-aware('color', 'color-text');
}
.exchange-confirmation-message-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: '100%';
  @include theme-aware('color', 'color-text');
  img {
    @include theme-aware('filter', 'filter-invert');
  }
}

.MuiButton-contained.Mui-disabled {
  opacity: 0.5 !important;
}

.radio-button:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  content: '';
  background-color: transparent;
  display: inline-block;
  visibility: visible;
  @include theme-aware('border', 'border-button');
}

.radio-button:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  @include theme-aware('background', 'color-main');
  content: '';
  display: inline-block;
  visibility: visible;
  @include theme-aware('border', 'border-button');
}

.MuiOutlinedInput-input:-webkit-autofill {
  border-radius: 30px !important;
}

.MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)
  .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  @include theme-aware('border', 'border-button');
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  @include theme-aware('border', 'border-disabled');
}

.MuiInputBase-input.Mui-disabled {
  @include theme-aware('color', 'color-gray');
}

.MuiPickersModal-dialogRoot {
  @include theme-aware('background', 'color-main');
}

.MuiIconButton-root {
  outline: none !important;
  background-color: transparent !important;
}

.MuiSvgIcon-root {
  @include theme-aware('fill', 'color-text');
}
.MuiButton-textPrimary {
  @include theme-aware('color', 'color-text');
}

.auth-language-holder {
  position: absolute;
  top: 5%;
  right: 10%;
  z-index: 999999;
  @media (max-width: 880px) {
    right: 0% !important;
    left: 50% !important;
    top: 8% !important;
    transform: translate(-50%, 0) !important;
  }
  .lang-dropdown {
    padding-left: 0 !important;
  }
}
.no-items {
  @include theme-aware('color', 'color-text');
}

.lang-dropdown {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999 !important;

  button {
    padding: 0 !important;
    margin: 0 !important;
  }

  .dropdown-menu {
    padding: 0 !important;
    margin-top: 10px !important;
    z-index: 9999999 !important;
  }

  .dropdown-toggle {
    a {
      @include theme-aware('color', 'color-text');
    }
  }

  .dropdown-toggle::after {
    display: none;
    content: '';
  }

  .flag-image {
    @include theme-aware('box-shadow', 'shadow');
    @include theme-aware('border', 'border-main');
    width: 24px;
    height: 24px;
    border-radius: 12px;
    float: right;
  }

  .dropdown-menu {
    @include theme-aware('background', 'color-background');
    @include theme-aware('box-shadow', 'shadow');
    li {
      &:hover {
        @include theme-aware('background', 'color-main');
      }

      a {
        @include theme-aware('color', 'color-text');
        text-decoration: none !important;
        display: block;
        padding: 10px 5px;
      }
    }
  }
}

.fx-rates-select {
  &__menu {
    @include theme-aware('background', 'color-background');
  }

  &__option {
    @include theme-aware('background', 'color-background');
    &--is-focused {
      @include theme-aware('background', 'color-gray');
    }
  }
}

.authorisation-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: white;

  .iconHolder {
    display: flex;
    height: auto;
    min-width: 40px;
    background: transparent;
    border: 1px solid #201d5c;
  }

  .amount-wrapper {
    color: #201d5c;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }
}

.lp-modal {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    color: $GRAY_COLOR;
  }
}
